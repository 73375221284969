import ApiError from '../types/response-error';
import ApiSuccess from '../types/response-success';
import RequestProtectedUtil from '../utils/request-protected.util';
import SortUtil from '../../../utils/sort.util';
import { internalServerErrorMessage } from '../const/errors.const';
import {
  BACKEND_NOTES_BASE,
  BACKEND_NOTES_ADD,
  BACKEND_NOTES_UPDATE,
  BACKEND_NOTES_DELETE,
  BACKEND_NOTES_GENERATE,
} from '../../../routes/backend.routes';
import NoteType from '../../../types/note.type';

class NoteService {
  static async list(): Promise<ApiError | ApiSuccess<NoteType[]>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_NOTES_BASE, 'GET');
      const json = await response.json();
      const jsonOrdered = new SortUtil<NoteType>().execute(json, 'name');
      return new ApiSuccess(response.status, jsonOrdered);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async add(note: NoteType): Promise<ApiError | ApiSuccess<NoteType>> {
    try {
      
      const response = await RequestProtectedUtil.handle(BACKEND_NOTES_ADD, 'POST', note);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async generate(id: string): Promise<ApiError | ApiSuccess<{}>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_NOTES_GENERATE.replace(':id', id), 'POST');
      return new ApiSuccess(response.status, {});
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async update(note: NoteType): Promise<ApiError | ApiSuccess<NoteType>> {
    if (!note.id) return new ApiError(400, 'Note id is required');

    try {
      const response = await RequestProtectedUtil.handle(BACKEND_NOTES_UPDATE.replace(':id', note.id), 'PATCH', note);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async delete(code: string): Promise<ApiError | ApiSuccess<{}>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_NOTES_DELETE.replace(':id', code), 'DELETE');
      return new ApiSuccess(response.status, {});
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }
}

export default NoteService;
