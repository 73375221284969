import JudgeType from '../../../types/judge.type';
import RequestProtectedUtil from '../utils/request-protected.util';
import SortUtil from '../../../utils/sort.util';
import ApiSuccess from '../types/response-success';
import ApiError from '../types/response-error';
import { internalServerErrorMessage } from '../const/errors.const';
import {
  BACKEND_JUDGE_ADD,
  BACKEND_JUDGE_BASE,
  BACKEND_JUDGE_DELETE,
  BACKEND_JUDGE_UPDATE,
} from '../../../routes/backend.routes';

class JudgeService {
  static async list(): Promise<ApiError | ApiSuccess<JudgeType[]>> {
    try {
      const response = await RequestProtectedUtil.handle(`${BACKEND_JUDGE_BASE}?${Date.now()}`, 'GET');
      const json = await response.json();
      const jsonOrdered = new SortUtil<JudgeType>().execute(json, 'name');
      return new ApiSuccess(response.status, jsonOrdered);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async add(name: string): Promise<ApiError | ApiSuccess<JudgeType>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_JUDGE_ADD, 'POST', { name });
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async update(judge: JudgeType): Promise<ApiError | ApiSuccess<JudgeType>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_JUDGE_UPDATE.replace(':id', judge._id), 'PATCH', judge);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async delete(code: string): Promise<ApiError | ApiSuccess<{}>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_JUDGE_DELETE.replace(':id', code), 'DELETE');
      return new ApiSuccess(response.status, {});
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }
}

export default JudgeService;
