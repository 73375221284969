export const ADMIN_URL_AUTH = '/carnaval-admin/auth';
export const ADMIN_URL_SIGN_IN = `${ADMIN_URL_AUTH}/sign-in`;
export const ADMIN_URL_SIGN_UP = `${ADMIN_URL_AUTH}/sign-up`;

export const ADMIN_URL_APP_BASE = '/carnaval-admin/app';

export const ADMIN_URL_APP_CARNAVAL = `${ADMIN_URL_APP_BASE}/carnavais`;
export const ADMIN_URL_APP_CARNAVAL_DETAILS = `${ADMIN_URL_APP_CARNAVAL}/:id`;
export const ADMIN_URL_APP_CARNAVAL_ADD = `${ADMIN_URL_APP_CARNAVAL}/add`;
export const ADMIN_URL_APP_CARNAVAL_UPDATE = `${ADMIN_URL_APP_CARNAVAL}/add/:id`;

export const ADMIN_URL_APP_SCHOOLS = `${ADMIN_URL_APP_BASE}/escolas`;

export const ADMIN_URL_APP_JUDGES = `${ADMIN_URL_APP_BASE}/juizes`;

export const ADMIN_URL_APP_CATEGORIES = `${ADMIN_URL_APP_BASE}/categorias`;

export const ADMIN_URL_APP_VERIFICATION = `${ADMIN_URL_APP_BASE}/apuracao/:id`;
