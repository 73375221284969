import React, { FC, useEffect, useState } from 'react';
import {
  Checkbox, Col, message, Modal, Row,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CarnavalType from '../types/carnaval.type';
import CategoryType from '../types/category.type';
import CategoryItemService from '../api/backend/services/category-item.service';

type CategoryItemAddProps = {
  carnival: CarnavalType;
  categories: CategoryType[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const CategoryItemAddComponent: FC<CategoryItemAddProps> = ({
  carnival, categories, visible, setVisible,
}) => {
  const [categoriesOnSave, setCategoriesOnSave] = useState<CategoryType[]>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    categoriesOnSave?.map(async (category, index) => {
      if (!carnival._id) throw new Error('Carnaval should have id');
      const response = await CategoryItemService.add(category, carnival._id);
      

      if ('message' in response.body) {
        setVisible(false);
        message.error(response.body.message);
      } else if (response.status === 201) {
        message.success(`A categoria ${category.name} foi adicionada com sucesso.`);
      }

      if (index === categoriesOnSave.length - 1) {
        setCategoriesOnSave([]);
        setConfirmLoading(false);
        setVisible(false);
      }
    });
  }

  function handleCancel() {
    setCategoriesOnSave([]);
    setConfirmLoading(false);
    setVisible(false);
  }

  function onCheckedCategories(value: CheckboxChangeEvent) {
    if (value.target.checked) {
      setCategoriesOnSave([...categoriesOnSave || [], value.target.value]);
    } else {
      const categorySearched = categoriesOnSave
        .find((category) => category._id === value.target.value._id);
      setCategoriesOnSave(categoriesOnSave
        .filter((category) => category._id !== categorySearched?._id));
    }
  }

  function categoriesWithoutSelected(): CategoryType[] {
    const categoryItems = carnival.categoryItem || [];
    return categories
      .filter((category) => categoryItems
        .every((categorySelected) => {
          return categorySelected.category._id !== category._id
        }));
  }

  useEffect(() => {}, [carnival]);

  return (
    <Modal title="Adicionar Categorias"
           visible={visible}
           onOk={handleOk}
           confirmLoading={confirmLoading}
           onCancel={handleCancel}
           afterClose={handleCancel}
           maskClosable={false}
           destroyOnClose >
      <Row>
        {categoriesWithoutSelected().map((category) => (
          <Col span={12}key={category._id}>
            <Checkbox
              value={category}
              onChange={(checked) => onCheckedCategories(checked)}
            >
              {category.name}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default CategoryItemAddComponent;
