import CarnavalType from '../../../types/carnaval.type';
import RequestProtectedUtil from '../utils/request-protected.util';
import SortUtil from '../../../utils/sort.util';
import ApiError from '../types/response-error';
import ApiSuccess from '../types/response-success';
import { internalServerErrorMessage } from '../const/errors.const';
import {
  BACKEND_CARNIVAL_BASE,
  BACKEND_CARNIVAL_FIND_BY_CODE,
  BACKEND_CARNIVAL_ADD,
  BACKEND_CARNIVAL_UPDATE,
  BACKEND_CARNIVAL_DELETE,
} from '../../../routes/backend.routes';
import CategoryItemType from '../../../types/category-item.type';
import JudgeType from '../../../types/judge.type';
import SchoolItemType from '../../../types/school-item.type';

class CarnavalService {
  static async list(): Promise<ApiError | ApiSuccess<CarnavalType[]>> {
    try {
      const response = await RequestProtectedUtil.handle(`${BACKEND_CARNIVAL_BASE}?${Date.now()}`, 'GET');
      const json = await response.json();
      const jsonOrderedByYear = new SortUtil<CarnavalType>().execute(json, 'year', 'desc');
      return new ApiSuccess(response.status, jsonOrderedByYear);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async findByCode(code: string): Promise<ApiError | ApiSuccess<CarnavalType>> {
    try {
      const response = await RequestProtectedUtil.handle(`${BACKEND_CARNIVAL_FIND_BY_CODE.replace(':id', code)}?${Date.now()}`, 'GET');
      const json = await response.json();
      json.penalties = new SortUtil<SchoolItemType>().executeSub(json?.penalties, 'school', 'name');
      // json.penalties = [];
      
      json.categoryItem = new SortUtil<CategoryItemType>().executeSub(json?.categoryItem, 'category', 'name');
      // json.categoryItem = [];

      json?.categoryItem?.forEach((item: CategoryItemType) => {
        // eslint-disable-next-line no-param-reassign
        item.judges = new SortUtil<JudgeType>().execute(item.judges, 'name');
      });
      // json.categoryItem.judges = []
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async add(carnival: CarnavalType): Promise<ApiError | ApiSuccess<CarnavalType>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CARNIVAL_ADD, 'POST', carnival);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async update(carnival: CarnavalType): Promise<ApiError | ApiSuccess<CarnavalType>> {
    if (!carnival._id) throw new Error('Carnaval id is required');

    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CARNIVAL_UPDATE.replace(':id', carnival._id), 'PATCH', carnival);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async finishedEvent(
    carnivalId: string | undefined,
    finished: boolean,
    winner: string,
  ): Promise<ApiError | ApiSuccess<CarnavalType>> {
    if (!carnivalId) throw new Error('Carnaval id is required');

    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CARNIVAL_UPDATE.replace(':id', carnivalId), 'PATCH', { winner, finished });
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async delete(code: string): Promise<ApiError | ApiSuccess<{}>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CARNIVAL_DELETE.replace(':id', code), 'DELETE');
      return new ApiSuccess(response.status, {});
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }
}

export default CarnavalService;
