import React, { useState, FC } from 'react';
import {
  Form, Input, message, Modal, Select,
} from 'antd';
import SchoolService from '../api/backend/services/school.service';
import SchoolType from '../types/school.type';
import CitiesUtil from '../utils/cities.util';

type SchoolUpdateProps = {
  school: SchoolType;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const SchoolUpdateComponent: FC<SchoolUpdateProps> = ({
  school, visible, setVisible,
}) => {
  const [cities] = useState(new CitiesUtil().execute);
  const [name, setName] = useState<string>(school.name);
  const [city, setCity] = useState<string>(school.city);
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    const schoolUpdated = { ...school, name, city };
    const response = await SchoolService.update(schoolUpdated);

    if ('message' in response.body) message.error(response.body.message);
    else if (response.status === 200) {
      message.success(`A escola ${response.body.name} foi atualizada com sucesso.`);
      setVisible(false);
    }

    setConfirmLoading(false);
  }

  function handleCancel() {
    setName('');
    setCity('');
    setConfirmLoading(false);
    setVisible(false);
  }

  return (
    <Modal
      title="Editar Escola"
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      afterClose={handleCancel}
      destroyOnClose
    >
      <Form layout="vertical">
        <Form.Item name="name" label="Nome" initialValue={name} required>
          <Input placeholder="Nome da escola..." disabled={confirmLoading} autoComplete="off" onChange={(event) => setName(event.target.value)} />
        </Form.Item>
        <Form.Item name="city" label="Cidade" required>
          <Select defaultValue={city} disabled={confirmLoading} onChange={setCity}>
            {cities.map((item) => (
              <Select.Option key={item.name} value={item.name}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SchoolUpdateComponent;
