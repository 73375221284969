class AuthUtil {
  saveToken(token: string) {
    sessionStorage.setItem('login', token.replace('"', ''));
  }

  getToken() {
    const token = sessionStorage.getItem('login');
    if (!token) return '';
    return `Bearer ${sessionStorage.getItem('login')}`;
  }

  deleteToken() {
    sessionStorage.removeItem('login');
  }
}

export default AuthUtil;
