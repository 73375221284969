import React, { FC, useState } from "react";
import { Form, InputNumber, message, Modal } from "antd";
import SchoolItemType from "../types/school-item.type";
import SchoolItemService from "../api/backend/services/school-item.service";
import CarnavalType from "../types/carnaval.type";

type SchoolItemUpdateComponentProps = {
  carnival: CarnavalType;
  setCarnival: (carnival: CarnavalType) => void;
  schoolItem: SchoolItemType;
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

const SchoolItemUpdateComponent: FC<SchoolItemUpdateComponentProps> = ({
  carnival,
  setCarnival,
  schoolItem,
  visible,
  setVisible,
}) => {
  const [penalty, setPenalty] = useState<string>(schoolItem.value);
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    if (!carnival._id) throw new Error("Carnival id is not defined");
    const event = { _id: carnival._id };
    const schoolUpdated = { ...schoolItem, event, value: penalty };
    const response = await SchoolItemService.update(schoolUpdated);

    if ("message" in response.body) {
      setVisible(false);
      message.error(response.body.message);
    } else if (response.status === 200) {
      message.success(
        `A escola ${schoolItem.school.name} foi alterada com sucesso.`
      );
      setConfirmLoading(false);
      setVisible(false);

      if (!carnival.penalties) return;
      const schoolItemUpdated = carnival.penalties.map((item) => {
        if (item._id === schoolItem._id) {
          return schoolUpdated;
        }
        return item;
      });
      setCarnival({ ...carnival, penalties: [...schoolItemUpdated] });
    }
  }

  function handleCancel() {
    setVisible(false);
  }

  return (
    <Modal
      title="Editar Escola"
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
    >
      <Form layout="vertical">
        <Form.Item
          initialValue={schoolItem.value}
          label="Penalidade"
          name="penalty"
          required
          wrapperCol={{ span: 24 }}
        >
          <InputNumber
            type="number"
            step={1}
            min={0}
            max={10}
            onChange={(value) => setPenalty(String(value))}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SchoolItemUpdateComponent;
