import RequestProtectedUtil from '../utils/request-protected.util';
import ApiError from '../types/response-error';
import ApiSuccess from '../types/response-success';
import { internalServerErrorMessage } from '../const/errors.const';
import {
  BACKEND_SCHOOL_ITEM_BASE,
  BACKEND_SCHOOL_ITEM_ADD,
  BACKEND_SCHOOL_ITEM_UPDATE,
  BACKEND_SCHOOL_ITEM_DELETE,
} from '../../../routes/backend.routes';
import SchoolItemType from '../../../types/school-item.type';
import SchoolType from '../../../types/school.type';

class SchoolItemService {
  static async list(): Promise<ApiError | ApiSuccess<SchoolItemType[]>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_SCHOOL_ITEM_BASE, 'GET');
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async add(
    school: SchoolType,
    eventId: string,
    value = '0',
  ): Promise<ApiError | ApiSuccess<SchoolItemType>> {
    try {
      const response = await RequestProtectedUtil.handle(
        BACKEND_SCHOOL_ITEM_ADD,
        "POST",
        {
          value: value,
          event: { _id: eventId },
          school: school,
        }
      );
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async update(school: SchoolItemType): Promise<ApiError | ApiSuccess<SchoolItemType>> {
    if (!school._id) return new ApiError(400, 'School item id is required');

    try {
      const response = await RequestProtectedUtil.handle(BACKEND_SCHOOL_ITEM_UPDATE.replace(':id', school._id), 'PATCH', school);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async delete(code: string): Promise<ApiError | ApiSuccess<{}>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_SCHOOL_ITEM_DELETE.replace(':id', code), 'DELETE');
      return new ApiSuccess(response.status, {});
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }
}

export default SchoolItemService;
