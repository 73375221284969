import React, { useEffect, useState } from 'react';
import {
  Button, Input, message, PageHeader, Popconfirm, Space, Table, Tag,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import JudgeAddComponent from '../components/judge-add.component';
import JudgeUpdateComponent from '../components/judge-update.component';
import JudgeService from '../api/backend/services/judge.service';
import ApiError from '../api/backend/types/response-error';
import ApiSuccess from '../api/backend/types/response-success';
import JudgeType from '../types/judge.type';

const { Column } = Table;

const JudgesPage = () => {
  const [search, setSearch] = useState<string>('');
  const [judges, setJudges] = useState<JudgeType[]>([]);
  const [judgeSelected, setJudgeSelected] = useState<JudgeType>();
  const [judgeModalAddIsVisible, setJudgeModalAddIsVisible] = useState(false);
  const [judgeModalUpdateIsVisible, setJudgeModalUpdateIsVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function load() {
    setLoading(true);

    const result: ApiError | ApiSuccess<JudgeType[]> = await JudgeService.list();

    if ('message' in result.body) message.error(result.body.message);
    else setJudges(result.body);

    setLoading(false);
  }

  function filter(): JudgeType[] {
    return judges
      .filter((judge: JudgeType) => judge.name.toLowerCase().includes(search.toLowerCase()));
  }

  function update(judge: JudgeType) {
    setJudgeSelected(judge);
    setJudgeModalUpdateIsVisible(true);
  }

  async function remove(judge: JudgeType) {
    setLoading(true);
    const result = await JudgeService.delete(judge._id);

    if ('message' in result.body) message.error(result.body.message);
    else if (result.status === 200) {
      const judgesResult = judges.splice(judges.indexOf(judge), 1);
      setJudges(judgesResult);
      message.success(`O juiz ${judge.name} foi removido com sucesso.`);
      await load();
    }

    setLoading(false);
  }

  useEffect(() => {
    load().then();
  }, []);

  useEffect(() => {
    load().then();
  }, [judgeModalAddIsVisible, judgeModalUpdateIsVisible]);

  function renderModalAdd() {
    return (
      <JudgeAddComponent
        visible={judgeModalAddIsVisible}
        setVisible={setJudgeModalAddIsVisible}
      />
    );
  }

  function renderModalUpdate() {
    if (!judgeSelected) return message.warning('Selecione um juiz para editar.');

    return (
      <JudgeUpdateComponent
        judge={judgeSelected}
        visible={judgeModalUpdateIsVisible}
        setVisible={setJudgeModalUpdateIsVisible}
      />
    );
  }

  function renderTagTotalJudges() {
    let phrase;
    let color;

    if ((judges && judges.length <= 0) || (search && filter().length <= 0)) {
      phrase = 'Nenhum juiz encontrado';
      color = 'red';
    } else {
      color = 'green';
      phrase = `${search ? filter().length : judges.length} juiz(es) encontrado(s).`;
    }

    return (<Tag color={color}>{ phrase }</Tag>);
  }

  function renderInputSearch() {
    return (
      <Input.Search
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => load()}
        placeholder="Nome do juiz..."
        required
        loading={loading}
      />
    );
  }

  function renderButtonNewJudge() {
    return (
      <Button
        type="primary"
        size="middle"
        htmlType="button"
        onClick={() => setJudgeModalAddIsVisible(true)}
      >
        Novo Juiz
      </Button>
    );
  }

  function renderPageHeaderExtra() {
    return (
      <Space>
        { renderTagTotalJudges() }
        { renderInputSearch() }
        { renderButtonNewJudge() }
      </Space>
    );
  }

  function renderActions(judge: JudgeType) {
    return (
      <Space size="middle">
        <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => update(judge)} />
        <Popconfirm title="Você tem certeza?" okText="Sim" cancelText="Não" onConfirm={() => remove(judge)}>
          <Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      </Space>
    );
  }

  function renderTable() {
    return (
      <Table
        dataSource={search ? filter() : judges}
        locale={{ emptyText: 'Nenhum juiz encontrado.' }}
        loading={loading}
        style={{ marginTop: 32 }}
        rowKey={(judge) => judge._id}
      >
        <Column title="Nome" dataIndex="name" key="name" />
        <Column title="Ações" key="action" render={(value) => renderActions(value)} />
      </Table>
    );
  }

  return (
    <div style={{ padding: 32 }}>
      <PageHeader title="Juízes" extra={renderPageHeaderExtra()} />
      { renderTable() }
      { judgeModalAddIsVisible && renderModalAdd() }
      { judgeModalUpdateIsVisible && renderModalUpdate() }
    </div>
  );
};

export default JudgesPage;
