import RequestProtectedUtil from '../utils/request-protected.util';
import ApiError from '../types/response-error';
import ApiSuccess from '../types/response-success';
import SchoolType from '../../../types/school.type';
import { internalServerErrorMessage } from '../const/errors.const';
import {
  BACKEND_SCHOOL_BASE,
  BACKEND_SCHOOL_ADD,
  BACKEND_SCHOOL_UPDATE,
  BACKEND_SCHOOL_DELETE,
} from '../../../routes/backend.routes';
import SortUtil from '../../../utils/sort.util';

class SchoolService {
  static async list(): Promise<ApiError | ApiSuccess<SchoolType[]>> {
    try {
      const response = await RequestProtectedUtil.handle(`${BACKEND_SCHOOL_BASE}?${Date.now()}`, 'GET');
      const json = await response.json();
      const jsonSort = new SortUtil<SchoolType>().execute(json, 'name');
      return new ApiSuccess(response.status, jsonSort);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async add(name: string, city: string): Promise<ApiError | ApiSuccess<SchoolType>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_SCHOOL_ADD, 'POST', { name, city });
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async update(school: SchoolType): Promise<ApiError | ApiSuccess<SchoolType>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_SCHOOL_UPDATE.replace(':id', school._id), 'PATCH', school);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async delete(code: string): Promise<ApiError | ApiSuccess<{}>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_SCHOOL_DELETE.replace(':id', code), 'DELETE');
      return new ApiSuccess(response.status, {});
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }
}

export default SchoolService;
