import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {
  Form, message, Row, Input, Col, Button, Card,
} from 'antd';
import {
  MailOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined, SendOutlined,
} from '@ant-design/icons';
import UserService from '../api/backend/services/user.service';
import AuthUtil from '../utils/auth.util';
import {ADMIN_URL_APP_CARNAVAL, ADMIN_URL_SIGN_UP} from '../routes/routes';

const SignInPage = () => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState<boolean>(false);
  const [redirectToSignUP, setRedirectToSignUP] = useState<boolean>(false);


  async function validateSignUp() {
    if (email.length < 8 || password.length < 4) return;

    setLoading(true);
    const response = await UserService.SignIn(email, password);

    if (response.status !== 201 && response.body?.message) {
      message.error(response.body.message);
      setLoading(false);

    } else {
      new AuthUtil().saveToken(response.body.access_token);
      setRedirectToDashboard(true);
      setLoading(false);
    }
    setLoading(false);
  }

  async function onFinish() {
    await validateSignUp();
  }

  useEffect(() => () => {
  }, []);

  if (redirectToDashboard) return <Navigate to={ADMIN_URL_APP_CARNAVAL}/>;
  if (redirectToSignUP) return <Navigate to={ADMIN_URL_SIGN_UP}/>;


  return (
    <Row justify="center" align="middle" className="h-100vh site-root bg-signin">
      <div className="site-card-border-less-wrapper">
        <Card style={{width: 320}} className="card-signin">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            wrapperCol={{md: 24}}
            className="box--login"
          >
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Insira seu e-mail antes de continuar!',
                },
                {type: 'email', message: 'O e-mail digitado é invalido!'},
              ]}
              wrapperCol={{md: 24}}
            >
              <Input
                type="email"
                size="large"
                placeholder="Insira seu e-mail"
                prefix={<MailOutlined/>}
                onChange={(event) => setEmail(event.target.value)}
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Insira sua senha antes de continuar!',
                },
              ]}
              wrapperCol={{md: 24}}
            >
              <Input.Password
                size="large"
                placeholder="Insira sua senha"
                prefix={<LockOutlined/>}
                iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                onChange={(event) => setPassword(event.target.value)}
                disabled={loading}
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span="12">
                <Button
                  type="primary"
                  icon={<SendOutlined/>}
                  loading={loading}
                  htmlType="submit"
                  style={{width: '100%'}}
                >
                  Entrar
                </Button>
              </Col>
              <Col span="12">
                <Button
                  onClick={() => setRedirectToSignUP(true)}
                  type="default"
                  disabled={loading}
                  style={{width: '100%'}}
                >
                  Cadastrar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </Row>
  );
};

export default SignInPage;
