import ApiError from '../types/response-error';
import ApiSuccess from '../types/response-success';
import RequestProtectedUtil from '../utils/request-protected.util';
import SortUtil from '../../../utils/sort.util';
import CategoryType from '../../../types/category.type';
import { internalServerErrorMessage } from '../const/errors.const';
import {
  BACKEND_CATEGORY_BASE,
  BACKEND_CATEGORY_ADD,
  BACKEND_CATEGORY_DELETE,
  BACKEND_CATEGORY_UPDATE,
} from '../../../routes/backend.routes';

class CategoryService {
  static async list(): Promise<ApiError | ApiSuccess<CategoryType[]>> {
    try {
      const response = await RequestProtectedUtil.handle(`${BACKEND_CATEGORY_BASE}?${Date.now()}`, 'GET');
      const json = await response.json();
      const jsonOrdered = new SortUtil<CategoryType>().execute(json, 'name');
      return new ApiSuccess(response.status, jsonOrdered);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async add(name: string): Promise<ApiError | ApiSuccess<CategoryType>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CATEGORY_ADD, 'POST', { name });
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async update(category: CategoryType): Promise<ApiError | ApiSuccess<CategoryType>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CATEGORY_UPDATE.replace(':id', category._id), 'PATCH', category);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async delete(code: string): Promise<ApiError | ApiSuccess<{}>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CATEGORY_DELETE.replace(':id', code), 'DELETE');
      return new ApiSuccess(response.status, {});
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }
}

export default CategoryService;
