import React, { FC, useEffect, useState } from 'react';
import {
  Checkbox, Col, message, Modal, Row,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CarnavalType from '../types/carnaval.type';
import SchoolType from '../types/school.type';
import SchoolItemType from '../types/school-item.type';
import SchoolItemService from '../api/backend/services/school-item.service';

type SchoolItemAddProps = {
  carnival: CarnavalType;
  schools: SchoolType[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const SchoolItemAddComponent: FC<SchoolItemAddProps> = ({
  carnival, schools, visible, setVisible,
}) => {
  if (!carnival.penalties) throw new Error('Carnaval should have penalties');

  const [schoolsSelected, setSchoolsSelected] = useState<SchoolItemType[]>(carnival.penalties);
  const [schoolsOnSave, setSchoolsOnSave] = useState<SchoolType[]>();
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    schoolsOnSave?.map(async (school, index) => {
      if (!carnival._id) throw new Error('Carnaval should have id');
      const response = await SchoolItemService.add(school, carnival._id);

      if ('message' in response.body) {
        setVisible(false);
        message.error(response.body.message);
      } else if (response.status === 201) {
        message.success(`A escola ${response.body.school.name} foi adicionada com sucesso.`);
      }
      if (index === schoolsOnSave.length - 1) {
        setSchoolsSelected([]);
        setSchoolsOnSave([]);
        setConfirmLoading(false);
        setVisible(false);
      }
    });
  }

  function handleCancel() {
    setSchoolsOnSave([]);
    setConfirmLoading(false);
    setSchoolsSelected([]);
    setVisible(false);
  }

  function onCheckedSchools(value: CheckboxChangeEvent) {
    if (value.target.checked) {
      setSchoolsOnSave([...schoolsOnSave || [], value.target.value]);
    } else {
      const schoolSearched = schoolsOnSave?.find((school) => school._id === value.target.value._id);
      setSchoolsOnSave(schoolsOnSave?.filter((school) => school._id !== schoolSearched?._id));
    }
  }

  function schoolsWithoutSelected(): SchoolType[] {
    const schoolsForCity = schools.filter((school) => school.city === carnival.city);
    return schoolsForCity
      .filter((school) => schoolsSelected
        .every((schoolSelected) => schoolSelected.school._id !== school._id));
  }

  useEffect(() => {
    setSchoolsSelected(carnival.penalties || []);
  }, [carnival,schoolsOnSave]);

  return (
    <Modal
      title="Adicionar Escolas"
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      afterClose={handleCancel}
      maskClosable={false}
      destroyOnClose
    >
      <Row>
        {schoolsWithoutSelected().map((school) => (
          <Col span={12} key={school._id}>
            <Checkbox
              value={school}
              onChange={(checked) => onCheckedSchools(checked)}
            >
              {school.name}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default SchoolItemAddComponent;
