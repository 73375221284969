class ApiError {
  readonly status: number;
  readonly body: { message: string };

  constructor(status: number, message: string) {
    this.status = status;
    this.body = { message };
  }
}

export default ApiError;
