import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Button, Col, Form, Input, message, Row,
} from 'antd';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
  SendOutlined,
} from '@ant-design/icons';
import UserService from '../api/backend/services/user.service';
import { ADMIN_URL_SIGN_IN } from '../routes/routes';

const SignUpPage = () => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState<boolean>(false);
  const [redirectToSignIn, setRedirectToSignIn] = useState<boolean>(false);


  async function validateSignUp() {
    if (email.length < 8 || password.length < 4) return;

    setLoading(true);
    const response = await UserService.SignUp(email, password);

    if (response.status !== 201 && response.body?.message) message.error(response.body.message);
    else {
      sessionStorage.setItem('login', JSON.stringify(response));
      setRedirectToDashboard(true);
    }

    setLoading(false);
  }

  async function onFinish() {
    await validateSignUp();
  }

  useEffect(() => () => {}, []);

  if (redirectToDashboard || redirectToSignIn) return <Navigate to={ADMIN_URL_SIGN_IN} />;


  return (
    <div className="w-100vw h-100vh">
      <Row justify="center" align="middle" className="h-100vh">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
          wrapperCol={{ md: 24 }}
          className="box--login"
        >
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              { required: true, message: 'Insira seu e-mail antes de continuar!' },
              { type: 'email', message: 'O e-mail digitado é invalido!' },
            ]}
            wrapperCol={{ md: 24 }}
          >
            <Input
              type="email"
              size="large"
              placeholder="Insira seu e-mail"
              prefix={<MailOutlined />}
              onChange={(event) => setEmail(event.target.value)}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            label="Senha"
            name="password"
            rules={[{ required: true, message: 'Insira sua senha antes de continuar!' }]}
            wrapperCol={{ md: 24 }}
          >
            <Input.Password
              size="large"
              placeholder="Insira sua senha"
              prefix={<LockOutlined />}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              onChange={(event) => setPassword(event.target.value)}
              disabled={loading}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span="12">
              <Button
                type="primary"
                icon={<SendOutlined />}
                loading={loading}
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Cadastrar
              </Button>
            </Col>
            <Col span="12">
              <Button type="default" disabled={loading} style={{ width: '100%' }} onClick={()=>setRedirectToSignIn(true)}>
                Logar
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
};

export default SignUpPage;
