import { BACKEND_USER_SIGN_IN, BACKEND_USER_SIGN_UP } from '../../../routes/backend.routes';
import ApiError from '../types/response-error';
import ApiSuccess from '../types/response-success';

class UserService {
  static readonly PASSWORD_ERROR_MESSAGE = 'Password is to weak! Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number or special character.';

  static async SignUp(email: string, password: string): Promise<ApiSuccess<void> | ApiError> {
    const user = { username: email, password };

    const header = new Headers();
    header.set('Content-Type', 'application/json');

    try {
      const response = await fetch(BACKEND_USER_SIGN_UP, { method: 'POST', body: JSON.stringify(user), headers: header });

      if (response.status === 201) return new ApiSuccess<void>(response.status, undefined);

      const json = await response.json();
      if (json.message[0] === this.PASSWORD_ERROR_MESSAGE) return new ApiError(400, 'A senha precisa ter 1 caractere maiúsculo, 1 caractere minúsculo, 1 número e 1 caractere especial.');
      return new ApiError(500, 'Ops! Ocorreu um problema desconhecido, tente entrar novamente!');
    } catch (error) {
      return new ApiError(500, 'Ops! Ocorreu um problema desconhecido, tente entrar novamente!');
    }
  }

  static async SignIn(email: string, password: string) {
    const login = { username: email, password };

    const header = new Headers();
    header.set('Content-Type', 'application/json');

    try {
      const response = await fetch(BACKEND_USER_SIGN_IN, { method: 'POST', body: JSON.stringify(login), headers: header });
      const json = await response.json();

      if (response.status === 401 && !json.accessToken) return new ApiError(401, 'E-mail ou senha inválidos.');

      if (response.status === 404 && !json.accessToken) return new ApiError(404, 'E-mail ou senha inválidos.');

      if (response.status === 500 && !json.accessToken) return new ApiError(500, 'Ops! Ocorreu um problema desconhecido, tente entrar novamente!');

      return new ApiSuccess(201, json);
    } catch (error) {
      return new ApiError(500, 'Ops! Ocorreu um problema desconhecido, tente entrar novamente!');
    }
  }
}

export default UserService;
