class ApiSuccess<T> {
  readonly status: number;
  readonly body: T;

  constructor(status: number, body: T) {
    this.status = status;
    this.body = body;
  }
}

export default ApiSuccess;
