import ApiError from '../types/response-error';
import ApiSuccess from '../types/response-success';
import RequestProtectedUtil from '../utils/request-protected.util';
import {
  BACKEND_CATEGORY_ITEM_BASE,
  BACKEND_CATEGORY_ITEM_ADD,
  BACKEND_CATEGORY_ITEM_DELETE,
  BACKEND_CATEGORY_ITEM_UPDATE,
} from '../../../routes/backend.routes';
import { internalServerErrorMessage } from '../const/errors.const';
import CategoryItemType from '../../../types/category-item.type';
import CategoryType from '../../../types/category.type';

class CategoryItemService {
  static async list(): Promise<ApiError | ApiSuccess<CategoryItemType[]>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CATEGORY_ITEM_BASE, 'GET');
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async listByName(name: string): Promise<ApiError | ApiSuccess<CategoryItemType[]>> {
    try {
      const response = await RequestProtectedUtil.handle(`${BACKEND_CATEGORY_ITEM_BASE}?name=${name}`, 'GET');
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async add(
    category: CategoryType,
    eventId: string,
  ): Promise<ApiError | ApiSuccess<CategoryItemType>> {
    try {
      const data = {
        category,
        event: { _id: eventId },
      };
      const response = await RequestProtectedUtil.handle(BACKEND_CATEGORY_ITEM_ADD, 'POST', data);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async update(
    category: CategoryItemType,
  ): Promise<ApiError | ApiSuccess<CategoryItemType>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CATEGORY_ITEM_UPDATE.replace(':id', category._id), 'PATCH', category);
      const json = await response.json();
      return new ApiSuccess(response.status, json);
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }

  static async delete(code: string): Promise<ApiError | ApiSuccess<{}>> {
    try {
      const response = await RequestProtectedUtil.handle(BACKEND_CATEGORY_ITEM_DELETE.replace(':id', code), 'DELETE');
      return new ApiSuccess(response.status, {});
    } catch (error) {
      return new ApiError(500, internalServerErrorMessage);
    }
  }
}

export default CategoryItemService;
