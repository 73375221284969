import React, { FC, useState } from 'react';
import {
  Form, Input, message, Modal,
} from 'antd';
import CategoryService from '../api/backend/services/category.service';
import CategoryType from '../types/category.type';

type CategoryUpdateProps = {
  category: CategoryType;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const CategoryUpdateComponent: FC<CategoryUpdateProps> = ({
  category, visible, setVisible,
}) => {
  const [name, setName] = useState<string>(category.name);
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    const categoryUpdated = { ...category, name };
    const response = await CategoryService.update(categoryUpdated);

    if ('message' in response.body) message.error(response.body.message);
    else if (response.status === 200) {
      message.success(`A categoria ${name} foi atualizada com sucesso.`);
      setVisible(false);
    }

    setConfirmLoading(false);
  }

  function handleCancel() {
    setName('');
    setConfirmLoading(false);
    setVisible(false);
  }

  return (
    <Modal
      title="Editar Categoria"
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      afterClose={handleCancel}
      destroyOnClose
    >
      <Form>
        <Form.Item name="name" initialValue={category.name} required>
          <Input placeholder="Nome da categoria..." disabled={confirmLoading} autoComplete="off" onChange={(event) => setName(event.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryUpdateComponent;
