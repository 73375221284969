import React, { useEffect, useState } from 'react';
import {
  Button, Input, message, PageHeader, Popconfirm, Space, Table, Tag,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import SchoolAddComponent from '../components/school-add.component';
import SchoolUpdateComponent from '../components/school-update.component';
import SchoolService from '../api/backend/services/school.service';
import ApiSuccess from '../api/backend/types/response-success';
import ApiError from '../api/backend/types/response-error';
import SchoolType from '../types/school.type';

const { Column } = Table;

const SchoolsPage = () => {
  const [search, setSearch] = useState<string>('');
  const [schools, setSchools] = useState<SchoolType[]>([]);
  const [schoolSelected, setSchoolSelected] = useState<SchoolType>();
  const [schoolModalAddIsVisible, setSchoolModalAddIsVisible] = useState(false);
  const [schoolModalUpdateIsVisible, setSchoolModalUpdateIsVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function load() {
    setLoading(true);

    const result: ApiError | ApiSuccess<SchoolType[]> = await SchoolService.list();

    if ('message' in result.body) message.error(result.body.message);
    else setSchools(result.body);

    setLoading(false);
  }

  function filter(): SchoolType[] {
    return schools
      .filter((school: SchoolType) => school.name.toLowerCase().includes(search.toLowerCase()));
  }

  function update(school: SchoolType) {
    setSchoolModalUpdateIsVisible(true);
    setSchoolSelected(school);
  }

  async function remove(school: SchoolType) {
    setLoading(true);

    const result = await SchoolService.delete(school._id);

    if ('message' in result.body) message.error(result.body.message);
    else if (result.status === 200) {
      const schoolsResult = schools.splice(schools.indexOf(school), 1);
      setSchools(schoolsResult);
      message.success(`A escola ${school.name} foi removida com sucesso.`);
      await load();
    }


    setLoading(false);
  }

  useEffect(() => {
    load().then();
  }, []);

  useEffect(() => {
    load().then();
  }, [schoolModalAddIsVisible, schoolModalUpdateIsVisible]);

  function renderModalAdd() {
    return (
      <SchoolAddComponent
        visible={schoolModalAddIsVisible}
        setVisible={setSchoolModalAddIsVisible}
      />
    );
  }

  function renderModalUpdate() {
    if (!schoolSelected) return message.warning('Selecione uma escola para editar.');

    return (
      <SchoolUpdateComponent
        key={`school-update-${schoolSelected._id}`}
        school={schoolSelected}
        visible={schoolModalUpdateIsVisible}
        setVisible={setSchoolModalUpdateIsVisible}
      />
    );
  }

  function renderTagTotalSchools() {
    let phrase;
    let color;

    if ((schools && schools.length <= 0) || (search && filter().length <= 0)) {
      phrase = 'Nenhuma escola encontrada';
      color = 'red';
    } else {
      color = 'green';
      phrase = `${search ? filter().length : schools.length} escola(s) encontrada(s).`;
    }

    return (<Tag color={color}>{ phrase }</Tag>);
  }

  function renderInputSearch() {
    return (
      <Input.Search
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => filter()}
        placeholder="Nome da escola..."
        required
        loading={loading}
      />
    );
  }

  function renderButtonNewSchool() {
    return (
      <Button
        type="primary"
        size="middle"
        htmlType="button"
        onClick={() => setSchoolModalAddIsVisible(true)}
        loading={loading}
      >
        Nova Escola
      </Button>
    );
  }

  function renderPageHeaderExtra() {
    return (
      <Space>
        { renderTagTotalSchools() }
        { renderInputSearch() }
        { renderButtonNewSchool() }
      </Space>
    );
  }

  function renderActions(school: SchoolType) {
    return (
      <Space size="middle">
        <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => update(school)} />
        <Popconfirm title="Você tem certeza?" okText="Sim" cancelText="Não" onConfirm={() => remove(school)}>
          <Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      </Space>
    );
  }

  function renderTable() {
    return (
      <Table
        dataSource={search ? filter() : schools}
        loading={loading}
        locale={{ emptyText: 'Nenhuma escola encontrada' }}
        style={{ marginTop: 32 }}
        rowKey={(school) => school._id}
      >
        <Column title="Nome" dataIndex="name" key="name" />
        <Column title="Cidade" dataIndex="city" key="city" />
        <Column title="Ações" key="action" render={(value) => renderActions(value)} />
      </Table>
    );
  }

  return (
    <div style={{ padding: 32 }}>
      <PageHeader title="Escolas" extra={renderPageHeaderExtra()} />
      { renderTable() }
      { schoolModalAddIsVisible && renderModalAdd() }
      { schoolModalUpdateIsVisible && renderModalUpdate() }
    </div>
  );
};

export default SchoolsPage;
