import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Input, message, PageHeader, Popconfirm, Space, Table, Tag, Tooltip,
} from 'antd';
import {
  DeleteOutlined, EditOutlined, PlusOutlined,
} from '@ant-design/icons';
import CarnavalService from '../api/backend/services/carnaval.service';
import CarnavalType from '../types/carnaval.type';
import {
  ADMIN_URL_APP_CARNAVAL_ADD,
  ADMIN_URL_APP_CARNAVAL_DETAILS,
  ADMIN_URL_APP_CARNAVAL_UPDATE,
} from '../routes/routes';

const { Column } = Table;

const CarnavaisPage = () => {
  const [search, setSearch] = useState<string>('');
  const [carnivals, setCarnivals] = useState<CarnavalType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function load() {
    setLoading(true);

    const result = await CarnavalService.list();

    if ('message' in result.body) message.error(result.body.message);
    else setCarnivals(result.body);

    setLoading(false);
  }

  function filter(): CarnavalType[] {
    return carnivals
      .filter((carnival: CarnavalType) => carnival.name.toLowerCase()
        .includes(search.toLowerCase()));
  }

  async function remove(carnival: CarnavalType) {
    setLoading(true);

    if (!carnival._id) message.error('Carnaval não encontrado');
    else {
      const result = await CarnavalService.delete(carnival._id);
      if ('message' in result.body) message.error(result.body.message);
      else if (result.status === 204) {
        message.success(`O evento ${carnival.name} foi removido com sucesso.`);
        await load();
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    load().then();
  }, []);

  function getTagTotalCarnavais() {
    let phrase;
    let color;

    if ((carnivals && carnivals.length <= 0) || (search && filter().length <= 0)) {
      phrase = 'Nenhuma escola encontrada';
      color = 'red';
    } else {
      color = 'green';
      phrase = `${search ? filter().length : carnivals.length} escola(s) encontrada(s).`;
    }

    return (<Tag color={color}>{ phrase }</Tag>);
  }

  function getButtonNewCarnaval() {
    return (
      <Link to={ADMIN_URL_APP_CARNAVAL_ADD}>
        <Button
          type="primary"
          size="middle"
          htmlType="button"
        >
          Novo Carnaval
        </Button>
      </Link>
    );
  }

  function getInputSearch() {
    return (
      <Input.Search
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => load()}
        placeholder="Nome do evento..."
        required
        loading={loading}
      />
    );
  }

  function pageHeaderExtra() {
    return (
      <Space>
        { getTagTotalCarnavais() }
        { getInputSearch() }
        { getButtonNewCarnaval() }
      </Space>
    );
  }

  function renderActions(carnivalSelected: CarnavalType) {
    if (!carnivalSelected._id) return message.error('Carnaval não encontrado');

    return (
      <Space size="middle">
        <Link to={ADMIN_URL_APP_CARNAVAL_DETAILS.replace(':id', carnivalSelected._id)}>
          <Tooltip title="Clique para adicionar quesitos, juízes ou iniciar a apuração.">
            <Button type="primary" ghost shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Link>
        <Link to={ADMIN_URL_APP_CARNAVAL_UPDATE.replace(':id', carnivalSelected._id)}>
          <Button type="primary" shape="circle" icon={<EditOutlined />} />
        </Link>
        <Popconfirm title="Você tem certeza?" okText="Sim" cancelText="Não" onConfirm={() => remove(carnivalSelected)}>
          <Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      </Space>
    );
  }

  function renderTable() {
    return (
      <Table
        dataSource={search ? filter() : carnivals}
        loading={loading}
        locale={{ emptyText: 'Nenhum carnaval encontrado' }}
        style={{ marginTop: 32 }}
        rowKey={(event) => event._id!}
      >
        <Column title="Nome" dataIndex="name" key="name" />
        <Column title="Cidade" dataIndex="city" key="city" />
        <Column title="Ano" dataIndex="year" key="year" />
        <Column title="D. das Campeãs" dataIndex="champions" key="champions" />
        <Column title="Rebaixadas" dataIndex="demotes" key="demotes" />
        <Column title="Descartar Minima" dataIndex="discard_min" key="discard_min" render={(value) => (value === true ? 'Sim' : 'Não')} />
        <Column title="Descartar Maxima" dataIndex="discard_max" key="discard_max" render={(value) => (value === true ? 'Sim' : 'Não')} />
        <Column title="Ações" key="action" render={(value) => renderActions(value)} />
      </Table>
    );
  }

  return (

    <div className="container-m" style={{ padding: 32 }}>
      <PageHeader title="Carnavais" extra={pageHeaderExtra()} />
      { renderTable() }
    </div>
  );
};

export default CarnavaisPage;
