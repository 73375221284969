import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import MainMenuComponent from './main-menu.component';

const LayoutPanelComponent: FC = () => {
  const { Content } = Layout;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <MainMenuComponent />
      <Layout>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutPanelComponent;
