import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import {
  LogoutOutlined,
  ShoppingOutlined,
  ProjectOutlined,
  UserAddOutlined,
  StarOutlined,
} from '@ant-design/icons';
import {
  ADMIN_URL_APP_CARNAVAL,
  ADMIN_URL_APP_SCHOOLS,
  ADMIN_URL_APP_JUDGES,
  ADMIN_URL_APP_CATEGORIES,
  ADMIN_URL_SIGN_IN,
} from '../routes/routes';
import AuthUtil from '../utils/auth.util';

function MainMenuComponent() {
  const { Sider } = Layout;

  const [collapsed, setCollapsed] = useState<boolean>(false);

  function onCollapse(isCollapsed: boolean) {
    setCollapsed(isCollapsed);
  }

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="logo" />
      <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
        <Menu.Item key="1" icon={<StarOutlined />}>
          <Link to={ADMIN_URL_APP_CARNAVAL}>
            Carnavais
          </Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<ShoppingOutlined />}>
          <Link to={ADMIN_URL_APP_SCHOOLS}>
            Escolas
          </Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<UserAddOutlined />}>
          <Link to={ADMIN_URL_APP_JUDGES}>
            Juízes
          </Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<ProjectOutlined />}>
          <Link to={ADMIN_URL_APP_CATEGORIES}>
            Categorias
          </Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<LogoutOutlined />}>
          <Link to={ADMIN_URL_SIGN_IN} onClick={() => new AuthUtil().deleteToken()}>
            Sair
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default MainMenuComponent;
