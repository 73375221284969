import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { ADMIN_URL_APP_CARNAVAL } from '../routes/routes';

type Props = {
  message: string;
};

const NotFoundPage: FC<Props> = ({ message }) => {
  function renderButtonBackHome() {
    return (
      <Link to={ADMIN_URL_APP_CARNAVAL}>
        <Button type="primary">{message}</Button>
      </Link>
    );
  }
  return (
    <div style={{ padding: 32 }}>
      <Result
        status="404"
        title="404"
        subTitle="Desculpe, essa página não existe ou o servidor está fora do ar."
        extra={renderButtonBackHome()}
      />
    </div>
  );
};

export default NotFoundPage;
