import AuthUtil from '../../../utils/auth.util';

class RequestProtectedUtil {
  static async handle(url: string, method: string, data: any = undefined) {
    const header = new Headers();
    header.set('Content-Type', 'application/json');
    header.set('Authorization', new AuthUtil().getToken());

    let body;
    if (data) {
      body = JSON.stringify(data);
    }

    return fetch(url, { headers: header, method, body });
  }
}

export default RequestProtectedUtil;
