import React, { useState, FC } from 'react';
import {
  Form, Input, message, Modal,
} from 'antd';
import JudgeService from '../api/backend/services/judge.service';

type JudgeAddProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const JudgeAddComponent: FC<JudgeAddProps> = ({ visible, setVisible }) => {
  const [name, setName] = useState<string>('');
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    const response = await JudgeService.add(name);

    if ('message' in response.body) message.error(response.body.message);
    else if (response.status === 201) {
      message.success(`O juiz ${response.body.name} foi criado com sucesso.`);
      setVisible(false);
    }

    setConfirmLoading(false);
  }

  function handleCancel() {
    setName('');
    setConfirmLoading(false);
    setVisible(false);
  }

  return (
    <Modal
      title="Adicionar Juiz"
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      afterClose={handleCancel}
      maskClosable={false}
      destroyOnClose
    >
      <Form layout="vertical">
        <Form.Item name="name" label="Nome" required>
          <Input placeholder="Nome do juiz..." disabled={confirmLoading} autoComplete="off" onChange={(event) => setName(event.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default JudgeAddComponent;
