import React, { FC, useState } from 'react';
import {
  Checkbox, Col, message, Modal, Row,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import CategoryItemType from '../types/category-item.type';
import JudgeType from '../types/judge.type';
import CategoryItemService from '../api/backend/services/category-item.service';
import CarnavalType from '../types/carnaval.type';

type CategoryItemUpdateProps = {
  carnival: CarnavalType;
  setCarnival: (carnival: CarnavalType) => void;
  judges: JudgeType[];
  categoryItem: CategoryItemType;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const CategoryItemUpdateComponent: FC<CategoryItemUpdateProps> = ({
  carnival, setCarnival, judges, categoryItem, visible, setVisible,
}) => {
  const [judgesSelected, setJudgesSelected] = useState<JudgeType[]>(categoryItem.judges);
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    const event = { _id: carnival._id };
    const categoryUpdated = { ...categoryItem, event, judges: judgesSelected as JudgeType[] };
    const response = await CategoryItemService.update(categoryUpdated);

    if ('message' in response.body) {
      setVisible(false);
      message.error(response.body.message);
    } else if (response.status === 200) {
      
      message.success(`A categoria ${categoryItem.category.name} foi alterada com sucesso.`);
      setConfirmLoading(false);
      setVisible(false);

      if (!carnival.categoryItem) return;
      const categoryItemUpdated = carnival.categoryItem
        .map((item) => {
          if (item._id === categoryItem._id) {
            return categoryUpdated;
          }
          return item;
        });
      setCarnival({ ...carnival, categoryItem: [...categoryItemUpdated] });
    }
  }

  function handleCancel() {
    setVisible(false);
  }

  function judgeIsCheck(judgeSelected: JudgeType) {
    return categoryItem.judges.some((judge) => judgeSelected._id === judge._id);
  }

  function onCheckedJudges(value: CheckboxChangeEvent) {
    if (value.target.checked) {
      setJudgesSelected([...judgesSelected, value.target.value]);
    } else {
      const judgeSearched = judgesSelected.find((judge) => judge._id === value.target.value._id);
      if (!judgeSearched) return;
      setJudgesSelected(judgesSelected.filter((judge) => judge._id !== judgeSearched._id));
    }
  }

  return (
    <Modal
      title="Editar Juízes"
      visible={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      maskClosable={false}
      width={800}
      destroyOnClose
    >
      <Row>
        {judges.map((judge) => (
          <Col span={12} key={judge._id}>
            <Checkbox
              defaultChecked={judgeIsCheck(judge)}
              value={judge}
              onChange={(checked) => onCheckedJudges(checked)}
            >
              {judge.name}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Modal>
  );
};

export default CategoryItemUpdateComponent;
