import React, { FC, useState } from 'react';
import {
  Form, Input, message, Modal,
} from 'antd';
import CategoryService from '../api/backend/services/category.service';

type CategoryAddProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const CategoryAddComponent: FC<CategoryAddProps> = ({ visible, setVisible }) => {
  const [name, setName] = useState<string>('');
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    const response = await CategoryService.add(name);

    if ('message' in response.body) message.error(response.body.message);
    else if (response.status === 201) {
      message.success(`A categoria ${response.body.name} foi criada com sucesso.`);
      setVisible(false);
    }

    setConfirmLoading(false);
  }

  function handleCancel() {
    setName('');
    setVisible(false);
    setConfirmLoading(false);
  }

  return (
    <Modal
      title="Adicionar Categoria"
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      afterClose={handleCancel}
      maskClosable={false}
      destroyOnClose
    >
      <Form>
        <Form.Item name="name" required>
          <Input placeholder="Nome da categoria..." disabled={confirmLoading} autoComplete="off" onChange={(event) => setName(event.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryAddComponent;
