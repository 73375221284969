const BACKEND_BASE = 'https://service.ig.com.br/carnaval_ig';
// const BACKEND_BASE = 'http://0.0.0.0:3024';


export const BACKEND_USER_SIGN_IN = `${BACKEND_BASE}/auth/login`;
export const BACKEND_USER_SIGN_UP = `${BACKEND_BASE}/users/register`;

export const BACKEND_CARNIVAL_BASE = `${BACKEND_BASE}/events`;
export const BACKEND_CARNIVAL_FIND_BY_CODE = `${BACKEND_CARNIVAL_BASE}/:id`;
export const BACKEND_CARNIVAL_ADD = `${BACKEND_CARNIVAL_BASE}`;
export const BACKEND_CARNIVAL_UPDATE = `${BACKEND_CARNIVAL_BASE}/:id`;
export const BACKEND_CARNIVAL_DELETE = `${BACKEND_CARNIVAL_BASE}/:id`;

export const BACKEND_JUDGE_BASE = `${BACKEND_BASE}/judges`;
export const BACKEND_JUDGE_ADD = `${BACKEND_JUDGE_BASE}`;
export const BACKEND_JUDGE_UPDATE = `${BACKEND_JUDGE_BASE}/:id`;
export const BACKEND_JUDGE_DELETE = `${BACKEND_JUDGE_BASE}/:id`;

export const BACKEND_SCHOOL_BASE = `${BACKEND_BASE}/schools`;
export const BACKEND_SCHOOL_ADD = `${BACKEND_SCHOOL_BASE}`;
export const BACKEND_SCHOOL_UPDATE = `${BACKEND_SCHOOL_BASE}/:id`;
export const BACKEND_SCHOOL_DELETE = `${BACKEND_SCHOOL_BASE}/:id`;

export const BACKEND_SCHOOL_ITEM_BASE = `${BACKEND_BASE}/penalties`;
export const BACKEND_SCHOOL_ITEM_ADD = `${BACKEND_SCHOOL_ITEM_BASE}`;
export const BACKEND_SCHOOL_ITEM_UPDATE = `${BACKEND_SCHOOL_ITEM_BASE}/:id`;
export const BACKEND_SCHOOL_ITEM_DELETE = `${BACKEND_SCHOOL_ITEM_BASE}/:id`;

export const BACKEND_CATEGORY_BASE = `${BACKEND_BASE}/categories`;
export const BACKEND_CATEGORY_ADD = `${BACKEND_CATEGORY_BASE}`;
export const BACKEND_CATEGORY_UPDATE = `${BACKEND_CATEGORY_BASE}/:id`;
export const BACKEND_CATEGORY_DELETE = `${BACKEND_CATEGORY_BASE}/:id`;
export const BACKEND_CATEGORY_ITEM_BASE = `${BACKEND_CATEGORY_BASE}Item`;
export const BACKEND_CATEGORY_ITEM_ADD = `${BACKEND_CATEGORY_BASE}Item`;
export const BACKEND_CATEGORY_ITEM_UPDATE = `${BACKEND_CATEGORY_BASE}Item/:id`;
export const BACKEND_CATEGORY_ITEM_DELETE = `${BACKEND_CATEGORY_BASE}Item/:id`;

export const BACKEND_NOTES_BASE = `${BACKEND_BASE}/notes`;
export const BACKEND_NOTES_GENERATE = `${BACKEND_NOTES_BASE}/event/:id`;
export const BACKEND_NOTES_ADD = `${BACKEND_NOTES_BASE}`;
export const BACKEND_NOTES_UPDATE = `${BACKEND_NOTES_BASE}/:id`;
export const BACKEND_NOTES_DELETE = `${BACKEND_NOTES_BASE}/:id`;
