import React, { useEffect, useState } from 'react';
import {
  Button, Input, message, PageHeader, Popconfirm, Space, Table, Tag,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import CategoryAddComponent from '../components/category-add.component';
import CategoryUpdateComponent from '../components/category-update.component';
import CategoryService from '../api/backend/services/category.service';
import ApiError from '../api/backend/types/response-error';
import ApiSuccess from '../api/backend/types/response-success';
import CategoryType from '../types/category.type';

const { Column } = Table;

const CategoriesPage = () => {
  const [search, setSearch] = useState<string>('');
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [categorySelected, setCategorySelected] = useState<CategoryType>();
  const [categoryModalAddIsVisible, setCategoryModalAddIsVisible] = useState(false);
  const [categoryModalUpdateIsVisible, setCategoryModalUpdateIsVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  async function load() {
    setLoading(true);

    const result: ApiError | ApiSuccess<CategoryType[]> = await CategoryService.list();

    if ('message' in result.body) message.error(result.body.message);
    else setCategories(result.body);

    setLoading(false);
  }

  function filter(): CategoryType[] {
    return categories
      .filter((category: CategoryType) => category.name.toLowerCase()
        .includes(search.toLowerCase()));
  }

  function update(category: CategoryType) {
    setCategorySelected(category);
    setCategoryModalUpdateIsVisible(true);
  }

  async function remove(category: CategoryType) {
    setLoading(true);

    const result = await CategoryService.delete(category._id);

    if ('message' in result.body) message.error(result.body.message);
    else if (result.status === 200) {
      const categoriesResults = categories.splice(categories.indexOf(category), 1);
      setCategories(categoriesResults);
      message.success(`A categoria ${category.name} foi removida com sucesso.`);
      await load();
    }

    setLoading(false);
  }

  useEffect(() => {
    load().then();
  }, []);

  useEffect(() => {
    load().then();
  }, [categoryModalAddIsVisible, categoryModalUpdateIsVisible]);

  function renderModalAdd() {
    return (
      <CategoryAddComponent
        visible={categoryModalAddIsVisible}
        setVisible={setCategoryModalAddIsVisible}
      />
    );
  }

  function renderModalUpdate() {
    if (!categorySelected) return message.warning('Selecione uma categoria para editar.');

    return (
      <CategoryUpdateComponent
        key={`category-update-${categorySelected._id}`}
        category={categorySelected}
        visible={categoryModalUpdateIsVisible}
        setVisible={setCategoryModalUpdateIsVisible}
      />
    );
  }

  function renderTagTotalCategories() {
    let phrase;
    let color;

    if ((categories && categories.length <= 0) || (search && filter().length <= 0)) {
      phrase = 'Nenhuma categoria encontrada';
      color = 'red';
    } else {
      color = 'green';
      phrase = `${search ? filter().length : categories.length} categoria(s) encontrada(s).`;
    }

    return (<Tag color={color}>{ phrase }</Tag>);
  }

  function renderInputSearch() {
    return (
      <Input.Search
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => load()}
        placeholder="Nome da categoria..."
        required
        loading={loading}
      />
    );
  }

  function renderButtonNewCategory() {
    return (
      <Button
        type="primary"
        size="middle"
        htmlType="button"
        onClick={() => setCategoryModalAddIsVisible(true)}
      >
        Nova Categoria
      </Button>
    );
  }

  function renderPageHeaderExtra() {
    return (
      <Space>
        { renderTagTotalCategories() }
        { renderInputSearch() }
        { renderButtonNewCategory() }
      </Space>
    );
  }

  function renderActions(category: CategoryType) {
    return (
      <Space size="middle">
        <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => update(category)} />
        <Popconfirm title="Você tem certeza?" okText="Sim" cancelText="Não" onConfirm={() => remove(category)}>
          <Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      </Space>
    );
  }

  function renderTable() {
    return (
      <Table
        dataSource={search ? filter() : categories}
        loading={loading}
        locale={{ emptyText: 'Nenhuma categoria encontrada' }}
        style={{ marginTop: 32 }}
        rowKey={(category) => category._id}
      >
        <Column title="Nome" dataIndex="name" key="name" />
        <Column title="Ações" key="action" render={(value) => renderActions(value)} />
      </Table>
    );
  }

  return (
    <div style={{ padding: 32 }}>
      <PageHeader title="Categorias" extra={renderPageHeaderExtra()} />
      { renderTable() }
      { categoryModalAddIsVisible && renderModalAdd() }
      { categoryModalUpdateIsVisible && renderModalUpdate() }
    </div>
  );
};

export default CategoriesPage;
