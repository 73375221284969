import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Badge,
  Breadcrumb, Button, Card, Col, Divider, message, PageHeader, Row, Space, Spin,
} from 'antd';
import { DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';
import CategoryItemUpdateComponent from '../components/category-item-update.component';
import CarnavalService from '../api/backend/services/carnaval.service';
import CarnavalType from '../types/carnaval.type';
import CategoryItemType from '../types/category-item.type';
import { ADMIN_URL_APP_CARNAVAL, ADMIN_URL_APP_VERIFICATION } from '../routes/routes';
import JudgeType from '../types/judge.type';
import JudgeService from '../api/backend/services/judge.service';
import SchoolItemType from '../types/school-item.type';
import SchoolItemUpdateComponent from '../components/school-item-update.component';
import SchoolItemAddComponent from '../components/school-item-add.component';
import SchoolType from '../types/school.type';
import SchoolService from '../api/backend/services/school.service';
import SchoolItemService from '../api/backend/services/school-item.service';
import CategoryService from '../api/backend/services/category.service';
import CategoryType from '../types/category.type';
import CategoryItemAddComponent from '../components/category-item-add.component';
import CategoryItemService from '../api/backend/services/category-item.service';

const CarnivalDetailsPage = () => {
  const [carnival, setCarnival] = useState<CarnavalType>();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [judges, setJudges] = useState<JudgeType[]>([]);
  const [schools, setSchools] = useState<SchoolType[]>([]);
  const [schoolSelected, setSchoolSelected] = useState<SchoolItemType>();
  const [categorySelected, setCategorySelected] = useState<CategoryItemType>();
  const [schoolModalAddIsVisible, setSchoolModalAddIsVisible] = useState(false);
  const [schoolModalUpdateIsVisible, setSchoolModalUpdateIsVisible] = useState(false);
  const [categoryModalAddIsVisible, setCategoryModalAddIsVisible] = useState(false);
  const [categoryModalUpdateIsVisible, setCategoryModalUpdateIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const loadCarnival=useCallback(async()=> {
    if (!id) throw new Error('id is required');
    const result = await CarnavalService.findByCode(id);
    if ('message' in result.body) message.error(result.body.message);
    else {
      setCarnival(result.body)
    };
  },[id])

  async function loadJudges() {
    const resultJudges = await JudgeService.list();

    if ('message' in resultJudges.body) message.error(resultJudges.body.message);
    else setJudges(resultJudges.body);
  }

  async function loadSchools() {
    const resultSchools = await SchoolService.list();
    if ('message' in resultSchools.body) message.error(resultSchools.body.message);
    else setSchools(resultSchools.body);
  }

  async function loadCategories() {
    const resultCategories = await CategoryService.list();

    if ('message' in resultCategories.body) message.error(resultCategories.body.message);
    else setCategories(resultCategories.body);
  }

  const load=useCallback(async()=> {
    setLoading(true);

    await loadCarnival();
    await loadJudges();
    await loadSchools();
    await loadCategories();

    setLoading(false);
  },[loadCarnival])

  function updateSchoolItem(schoolItem: SchoolItemType) {
    setSchoolSelected(schoolItem);
    setSchoolModalUpdateIsVisible(true);
  }

  async function deleteSchoolItem(schoolItem: SchoolItemType) {
    if (!schoolItem._id) throw new Error('school is required');
    const result = await SchoolItemService.delete(schoolItem._id);
    if ('message' in result.body) message.error(result.body.message);
    else if (result.status === 200) {
      message.success(`A escola ${schoolItem.school.name} foi removida com sucesso!`);
      await loadCarnival();
    }
  }

  function updateCategoryItem(categoryItem: CategoryItemType) {
    setCategorySelected(categoryItem);
    setCategoryModalUpdateIsVisible(true);
  }

  async function deleteCategoryItem(categoryItem: CategoryItemType) {
    const result = await CategoryItemService.delete(categoryItem._id);
    if ('message' in result.body) message.error(result.body.message);
    else if (result.status === 200) {
      message.success(`A categoria ${categoryItem.category.name} foi removida com sucesso!`);
      await loadCarnival();
    }
  }

  useEffect(() => {
    load().then();
  }, [load]);

  useEffect(() => {
    loadCarnival().then();
  }, [schoolModalAddIsVisible, categoryModalAddIsVisible, loadCarnival]);


  function renderSpin() {
    return (
      <div className="spin-container">
        <Spin size="large" />
      </div>
    );
  }

  function renderSchoolsCards() {
    if (!carnival?.penalties || loading) return renderSpin();

    return (
      <div className="site-card-wrapper">
        <h1>Escolas</h1>
        <Row gutter={[16, 16]}>
          {carnival?.penalties?.map((schoolItem,index) => (
            <Col span={6} key={index}>
              <Badge.Ribbon text={`Penalidade: ${schoolItem.value}`} color="red">
                <Card
                  className="card card-category-item"
                  key={schoolItem._id}
                  title={schoolItem.school.name}
                  actions={[
                    <Button type="link" icon={<EditOutlined />} onClick={() => updateSchoolItem(schoolItem)}>Editar</Button>,
                    <Button type="link" danger icon={<DeleteOutlined />} onClick={() => deleteSchoolItem(schoolItem)}>Excluir</Button>,
                  ]}
                />
              </Badge.Ribbon>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  function renderCategoryCards() {
    return (
      <div className="site-card-wrapper">
        <h1>Categorias</h1>
        <Row gutter={[16, 16]}>
          {carnival?.categoryItem?.map((categoryItem, index) => (
            <Col span={6} key={index}>
              <Badge.Ribbon text={`Juízes: ${categoryItem.judges.length}`} color="blue">
                <Card
                  className="card card-category-item"
                  key={categoryItem._id}
                  title={categoryItem.category.name}
                  actions={[
                    <Button type="link" icon={<UserAddOutlined />} onClick={() => updateCategoryItem(categoryItem)}>Juízes</Button>,
                    <Button type="link" danger icon={<DeleteOutlined />} onClick={() => deleteCategoryItem(categoryItem)}>Excluir</Button>,
                  ]}
                />
              </Badge.Ribbon>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  function renderModalSchoolsAdd() {
    if (!schools || !carnival) return;

    return (
      <SchoolItemAddComponent
        carnival={carnival}
        schools={schools}
        visible={schoolModalAddIsVisible}
        setVisible={setSchoolModalAddIsVisible}
      />
    );
  }

  function renderModalSchoolsUpdate() {
    if (!schoolSelected) throw new Error('No school selected');
    if (!carnival?._id) throw new Error('No category selected');

    return (
      <SchoolItemUpdateComponent
        key={schoolSelected._id}
        carnival={carnival}
        setCarnival={setCarnival}
        schoolItem={schoolSelected}
        visible={schoolModalUpdateIsVisible}
        setVisible={setSchoolModalUpdateIsVisible}
      />
    );
  }

  function renderModalCategoriesAdd() {
    if (!carnival?._id || !categories) return;

    // eslint-disable-next-line consistent-return
    return (
      <CategoryItemAddComponent
        key={carnival._id}
        carnival={carnival}
        categories={categories}
        visible={categoryModalAddIsVisible}
        setVisible={setCategoryModalAddIsVisible}
      />
    );
  }

  function renderModalCategoryUpdate() {
    if (!categorySelected || !carnival?._id || !judges) return;

    // eslint-disable-next-line consistent-return
    return (
      <CategoryItemUpdateComponent
        key={categorySelected._id}
        carnival={carnival}
        setCarnival={setCarnival}
        judges={judges.sort((a, b) => a.name.localeCompare(b.name))}
        categoryItem={categorySelected}
        visible={categoryModalUpdateIsVisible}
        setVisible={setCategoryModalUpdateIsVisible}
      />
    );
  }

  function renderButtonStartVerification() {
    if (!carnival?._id) return;

    // eslint-disable-next-line consistent-return
    return (
      <Link to={ADMIN_URL_APP_VERIFICATION.replace(':id', carnival._id)}>
        <Button
          type="primary"
          size="middle"
          htmlType="button"
        >
          Iniciar Apuração
        </Button>
      </Link>
    );
  }

  function renderButtonAddCategory() {
    return (
      <Button
        type="default"
        size="middle"
        htmlType="button"
        onClick={() => setCategoryModalAddIsVisible(true)}
      >
        Definir Categorias
      </Button>
    );
  }

  function renderButtonAddSchools() {
    return (
      <Button
        type="default"
        size="middle"
        htmlType="button"
        onClick={() => setSchoolModalAddIsVisible(true)}
      >
        Definir Escolas
      </Button>
    );
  }

  function renderBreadCrumb() {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ADMIN_URL_APP_CARNAVAL}>
            Carnavais
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Detalhes
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  function renderPageHeaderExtra() {
    return (
      <Space>
        { renderButtonAddSchools() }
        { renderButtonAddCategory() }
        { renderButtonStartVerification() }
      </Space>
    );
  }

  return (
    <div style={{ padding: 32 }}>
      <PageHeader title="Detalhes do Carnaval" extra={renderPageHeaderExtra()} breadcrumbRender={renderBreadCrumb} />
      { loading ? renderSpin() : (
        <div>
          <Divider />
          { renderSchoolsCards() }
          <Divider />
          { renderCategoryCards() }
        </div>
      )}
      { schools && renderModalSchoolsAdd() }
      { categories && renderModalCategoriesAdd() }
      { categorySelected && renderModalCategoryUpdate() }
      { schoolSelected && renderModalSchoolsUpdate() }
    </div>
  );
};

export default CarnivalDetailsPage;
