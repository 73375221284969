import React, { useState, FC } from 'react';
import {
  Form, Input, message, Modal, Select,
} from 'antd';
import SchoolService from '../api/backend/services/school.service';
import CitiesUtil from '../utils/cities.util';

type SchoolAddProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const SchoolAddComponent: FC<SchoolAddProps> = ({ visible, setVisible }) => {
  const [cities] = useState(new CitiesUtil().execute);
  const [name, setName] = useState<string>('');
  const [city, setCity] = useState<string>(cities[0].name);
  const [confirmLoading, setConfirmLoading] = useState(false);

  async function handleOk() {
    setConfirmLoading(true);

    const response = await SchoolService.add(name, city);

    if ('message' in response.body) message.error(response.body.message);
    else if (response.status === 201) {
      message.success(`A escola ${response.body.name} foi criada com sucesso.`);
      setVisible(false);
    }

    setConfirmLoading(false);
  }

  function handleCancel() {
    setName('');
    setCity('');
    setConfirmLoading(false);
    setVisible(false);
  }

  return (
    <Modal
      title="Adicionar Escola"
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleOk}
      onCancel={handleCancel}
      afterClose={handleCancel}
      maskClosable={false}
      destroyOnClose
    >
      <Form layout="vertical">
        <Form.Item name="name" label="Nome" required>
          <Input placeholder="Nome da escola..." disabled={confirmLoading} autoComplete="off" onChange={(event) => setName(event.target.value)} />
        </Form.Item>
        <Form.Item name="city" label="Cidade" required>
          <Select defaultValue={city} disabled={confirmLoading} onChange={setCity}>
            {cities.map((item) => (
              <Select.Option key={item.name} value={item.name}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SchoolAddComponent;
