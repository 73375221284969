import React, { useCallback, useEffect, useState } from 'react';
import {
  Link, Navigate, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Row, Select,
  Space,
  Spin,
  Switch,
} from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import CarnavalService from '../api/backend/services/carnaval.service';
import CarnavalType from '../types/carnaval.type';
import { ADMIN_URL_APP_CARNAVAL } from '../routes/routes';
import CitiesUtil from '../utils/cities.util';

const { Item } = Form;

function CarnavalAddPage() {
  const [cities] = useState(new CitiesUtil().execute);
  const [carnival, setCarnival] = useState<CarnavalType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [redirectToCarnival, setRedirectToCarnival] = useState<boolean>(false);

  const { id } = useParams();

  const load =useCallback (async() =>{
    if (!id) throw new Error('Carnaval code not found');

    setLoading(true);
    const result = await CarnavalService.findByCode(id);
    if ('message' in result.body) message.error(result.body.message);
    else setCarnival(result.body);

    setLoading(false);
  },[id])

  async function save(values: any) {
    setLoading(true);

    let result;
    
      const newCarnival: CarnavalType = {
        _id: carnival?._id,
        name: values.name,
        city: values.city,
        year: values.year,
        champions: values.champions,
        demotes: values.demotes,
        discard_min: values.discard_min,
        discard_max: values.discard_max,
        finished: false,
        winner: '',
      };

    if (id && newCarnival?._id) {
      result = await CarnavalService.update(newCarnival);
    } else result = await CarnavalService.add(newCarnival);

    if ('message' in result.body) message.error(result.body.message);
    else {
      message.success(`O evento ${values.name} foi ${id ? 'editado' : 'adicionado'} com sucesso!`);
      setRedirectToCarnival(true);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (id) load().then();
  }, [id, load]);

  function renderBreadCrumb() {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ADMIN_URL_APP_CARNAVAL}>
            Carnaval
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? 'Editar Evento' : 'Cadastrar Evento'}
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  function renderSpin() {
    return (
      <Space
        align="center"
        style={{
          width: '100%', height: 'calc(100% - 128px)', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </Space>
    );
  }

  function renderActions() {
    return (
      <Space>
        <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>Registrar</Button>
        <Button
          type="default"
          disabled={loading}
          icon={<CloseOutlined />}
          onClick={() => setRedirectToCarnival(true)}
        >
          Cancelar
        </Button>
      </Space>
    );
  }

  function renderForm() {
    return (
      <Form layout="vertical" onFinish={(values) => save(values)} style={{ width: '100%', marginTop: 16 }}>
        <Row gutter={24}>
          <Col span="24">
            <Item label="Nome do Evento" name="name" initialValue={carnival?.name} required wrapperCol={{ span: 24 }} tooltip="Nome do evento e nome do grupo (ex: Carnaval Grupo Especial).">
              <Input placeholder="Ex. Carnaval Grupo Especial" autoComplete="off" />
            </Item>
          </Col>
          <Col span="12">
            <Item label="Cidade" name="city" initialValue={carnival?.city} required tooltip="Cidade onde o evento é realizado." wrapperCol={{ span: 24 }}>
              <Select defaultValue={carnival?.city} disabled={loading}>
                {cities.map((item) => (
                  <Select.Option key={item.name} value={item.name}>{item.name}</Select.Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span="12">
            <Item label="Ano" name="year" initialValue={carnival?.year || 2023} required wrapperCol={{ span: 24 }}>
              <InputNumber step="1" min="2022" max="2050" style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span="6">
            <Item label="Desfile das campeãs" name="champions" initialValue={carnival?.champions || 1} required tooltip="Número de escolas que participarão do desfile das campeãs." wrapperCol={{ span: 24 }}>
              <InputNumber step="1" min="1" autoComplete="off" style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span="6">
            <Item label="Rebaixadas" name="demotes" initialValue={carnival?.demotes || 1} required tooltip="Número de escolas que serão rebaixadas no campeonato." wrapperCol={{ span: 24 }}>
              <InputNumber step="1" min="1" style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span="6">
            <Item label="Descartar Mínimas" name="discard_min" initialValue={carnival?.discard_min || false} valuePropName="checked" required tooltip="Se ativado, as notas mínimas de cada quesito serão descartadas." wrapperCol={{ span: 24 }}>
              <Switch />
            </Item>
          </Col>
          <Col span="6">
            <Item label="Descartar Máximas" name="discard_max" initialValue={carnival?.discard_max || false} valuePropName="checked" required tooltip="Se ativado, as notas máximas de cada quesito serão descartadas." wrapperCol={{ span: 24 }}>
              <Switch />
            </Item>
          </Col>
          <Col span="12">
            { renderActions() }
          </Col>
        </Row>
      </Form>
    );
  }

  if (redirectToCarnival) return <Navigate to={ADMIN_URL_APP_CARNAVAL} />;

  return (
    <div style={{ padding: 32, height: '100%' }}>
      <PageHeader title={id ? 'Editar Evento' : 'Novo Evento'} breadcrumbRender={renderBreadCrumb} />
      { (!id) || (id && !loading) ? renderForm() : renderSpin() }
    </div>
  );
}

export default CarnavalAddPage;
