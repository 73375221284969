import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoutesComponent from './components/protected-routes.component';
import PublicRoutesComponent from './components/public-routes.component';
import LayoutPanelComponent from './components/layout-panel.component';
import SignUpPage from './pages/sign-up.page';
import SignInPage from './pages/sign-in.page';
import CarnavaisPage from './pages/carnavais.page';
import CarnivalDetailsPage from './pages/carnival-details.page';
import CarnavalAddPage from './pages/carnaval-add.page';
import SchoolsPage from './pages/schools.page';
import JudgesPage from './pages/judges.page';
import CategoriesPage from './pages/categories.page';
import AscertainmentPage from './pages/ascertainment.page';
import NotFoundPage from './pages/not-found.page';
import {
  ADMIN_URL_APP_BASE,
  ADMIN_URL_APP_CARNAVAL,
  ADMIN_URL_APP_CARNAVAL_ADD,
  ADMIN_URL_APP_CATEGORIES,
  ADMIN_URL_APP_JUDGES,
  ADMIN_URL_APP_SCHOOLS,
  ADMIN_URL_SIGN_IN,
  ADMIN_URL_SIGN_UP,
  ADMIN_URL_AUTH,
  ADMIN_URL_APP_CARNAVAL_DETAILS, ADMIN_URL_APP_CARNAVAL_UPDATE, ADMIN_URL_APP_VERIFICATION,
} from './routes/routes';
import './styles/App.css';


const App: FC = () => (
  <Routes>
    <Route path={ADMIN_URL_APP_BASE} element={<ProtectedRoutesComponent />}>
      <Route path={ADMIN_URL_APP_BASE} element={<LayoutPanelComponent />}>
        <Route path={ADMIN_URL_APP_CARNAVAL} element={<CarnavaisPage />} />
        <Route path={ADMIN_URL_APP_CARNAVAL_DETAILS} element={<CarnivalDetailsPage />} />
        <Route path={ADMIN_URL_APP_CARNAVAL_ADD} element={<CarnavalAddPage />} />
        <Route path={ADMIN_URL_APP_CARNAVAL_UPDATE} element={<CarnavalAddPage />} />
        <Route path={ADMIN_URL_APP_SCHOOLS} element={<SchoolsPage />} />
        <Route path={ADMIN_URL_APP_JUDGES} element={<JudgesPage />} />
        <Route path={ADMIN_URL_APP_CATEGORIES} element={<CategoriesPage />} />
        <Route path={ADMIN_URL_APP_VERIFICATION} element={<AscertainmentPage />} />
        <Route path="*" element={<NotFoundPage message="Voltar ao Dashboard" />} />
      </Route>
    </Route>
    <Route path={ADMIN_URL_AUTH} element={<PublicRoutesComponent />}>
      <Route path={ADMIN_URL_SIGN_UP} element={<SignUpPage />} />
      <Route path={ADMIN_URL_SIGN_IN} element={<SignInPage />} />
      <Route path="*" element={<Navigate to={ADMIN_URL_SIGN_IN} />} />
    </Route>
    <Route path="*" element={<Navigate to={ADMIN_URL_SIGN_IN} />} />
  </Routes>
);

export default App;
