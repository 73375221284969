import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthUtil from '../utils/auth.util';
import { ADMIN_URL_SIGN_IN } from '../routes/routes';

const ProtectedRoutesComponent = () => {
  const token = new AuthUtil().getToken();
  return token ? <Outlet /> : <Navigate to={ADMIN_URL_SIGN_IN} />;
};

export default ProtectedRoutesComponent;
