class SortUtil<T> {
  execute(arr: T[], field: string, direction = 'asc'): T[] {
    return arr.sort((a: any, b: any) => {
      if (direction === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      }
      return a[field] < b[field] ? 1 : -1;
    });
  }

  executeSub(arr: T[], field: string, subfield: string, direction = 'asc'): T[] {
    return arr.sort((a: any, b: any) => {
      if (direction === 'asc') {
        return a[field][subfield] > b[field][subfield] ? 1 : -1;
      }
      return a[field][subfield] < b[field][subfield] ? 1 : -1;
    });
  }
}

export default SortUtil;
