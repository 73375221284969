import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthUtil from '../utils/auth.util';
import { ADMIN_URL_APP_CARNAVAL } from '../routes/routes';

const PublicRoutesComponent = () => {
  const token = new AuthUtil().getToken();
  return !token ? <Outlet /> : <Navigate to={ADMIN_URL_APP_CARNAVAL} />;
};

export default PublicRoutesComponent;
