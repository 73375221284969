import SortUtil from './sort.util';

type City = {
  name: string;
};

class CitiesUtil {
  execute(): City[] {
    const cities: City[] = [
      { name: 'São Paulo' },
      { name: 'Rio de Janeiro' },
      { name: 'Vitoria' },
      { name: 'Belo Horizonte' },
      { name: 'Salvador' },
    ];
    return new SortUtil<City>().execute(cities, 'name');
  }
}

export default CitiesUtil;
